$levels: 1
$md-width: 1023px
$c-black: #231f20
$c-gray-light: #c5c5c5
$c-gilmation-red: rgb(172, 23, 27)
$c-gilmation-red-light: rgba(172, 23, 27, .8)

=active-level-x
  @for $i from 1 through $levels
    .csstransitions.csstransforms #mobile-nav .active-level-#{$i}
      transform: translateX(#{-$i*100%})
      -ms-transform: translateX(#{-$i*100%})
      -moz-transform: translateX(#{-$i*100%})
      -o-transform: translateX(#{-$i*100%})
      -webkit-transform: translateX(#{-$i*100%})
      transform: translateX(#{-$i*100%})
      left: 0
    #mobile-nav .active-level-#{$i}
      left: #{-$i*100%}

@media (max-width: $md-width)
  @include active-level-x
  #main-wrapper
    overflow: hidden
  #s-mask
    transition: transform 500ms ease
    position: relative
  #mobile-nav
    width: 90%
    z-index: -1
    position: absolute
    overflow: hidden
    transform: translate3d(-100%, 0, 0)
    #nav-back-link
      background-color: white
      padding: 12px  15px
      text-align: left
      a
        color: $c-black
        text-transform: uppercase
        font-size: 90%
        line-height: 0.9
        padding-left: 20px
        position: relative
        &:before, &:after
          $width: 16px
          $height: 5px
          transform: rotate(-45deg)
          content: ''
          position: absolute
          top: 50%
          left: 8px
          margin-top: calc(-1 * $height / 2)
          margin-left: calc(-1 * $width / 2)
          display: block
          height: $height
          width: $width
          background-color: white
          transition: all 0.2s ease-out
        &:after
          transform: rotate(45deg)
        &:hover
          &:before, &:after
            transform: rotate(0deg)
    ul
      padding: 0
      margin: 0
      clear: both
      position: relative
      transition: transform .3s linear
    li
      background-image: none
      border-top: solid 1px #bfc1c3
      background-color: white
      margin: 0
      width: auto
      list-style: none
      &:hover
        background-color: white
        & a
          color: $c-gilmation-red-light
      a
        display: block
        padding: 15px 0 15px 15px
        color: $c-black
        text-decoration: none
      &.current
        background: white
        a
          color: $c-gilmation-red

    > ul ul
      position: absolute
      left: 100%
      width: 100%
      display: none
      top: 0
    > ul ul.active
      display: block
  #holder
    box-shadow: -1px 0 10px -2px rgba(0, 0, 0, .36)
    overflow: auto
    header
      #mobile-nav-open
        display: block
        text-indent: 0
        color: white
        text-decoration: none
        vertical-align: middle
        width: 30px
        height: 21px
        overflow: hidden
        &:before
          content: ""
          display: block
          border-top: 10px double $c-black
          border-bottom: 3px solid $c-black
          width: 25px
          height: 4px
          box-sizing: content-box
  body.nav-open #s-mask
    left: 90%
  .csstransitions.csstransforms body.nav-open #s-mask
    -ms-transform: translateX(90%)
    -moz-transform: translateX(90%)
    -o-transform: translateX(90%)
    -webkit-transform: translateX(90%)
    transform: translateX(90%)
    left: 0
