@import "base-min";
@import "buttons-min";
@import "forms-min";
@import "tables-min";
@import "menus-min";
@import "grids-core-min";
@import "grids-units-min";
@import "grids-responsive-min";
@import "jquery.horizontal-slide-mobile-menu.v0.0.1.min";
@import "base";

$c-black: #333;
$c-anchor: #60B0D6;
$c-gold-light: #dabd3a;
$c-gold-med: #CCAE28;
$c-gold-dark: #795F11;
$c-gray-light: #c5c5c5;
$c-gray-med: #aaa;
$c-gray-dark: #616161;

$c-gilmation-black: #231f20;

$c-h: #A8B8C0;

$footer: #E2E2EC;
$footer-background: #A8B8C0;

$header-background: rgb(226, 226, 236);
$header-border-bottom: rgba(0, 0, 0, 0.1);

$nav-link: rgb(1, 41, 103);

$c-gilmation-red: rgb(172, 23, 27);
$c-gilmation-red-light: rgba(172, 23, 27, .8);


/* typography */

html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html, button, input, select, textarea,
.pure-g [class *= "pure-u"] {
  font-family: "Roboto", sans-serif;
}

.pure-g {
  letter-spacing: normal;
}

body {
  font-size: 14px;
  line-height: 1.5;
  min-height: 100%;
  margin: 0;
  position: relative;
  color: $c-gilmation-black;
  background-color: #FFF;
}

header {
  .home-menu {
    padding: 0;
    text-align: center;
    background-color: white;
    &.pure-menu-fixed {
      border-bottom: none;
      z-index: 4;
    }
    ul {
      float: right;
    }
    li a {
      color: rgb(1, 41, 103);
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: 0.15em;
      padding-top: 38px;
      padding-bottom: 38px;
    }
    li.current a {
      color: $c-gilmation-red;
    }
    li a:hover {
      color: $c-gilmation-red-light;
      background-color: #FFF;
    }
  }
  #mobile-nav-link{
    position: absolute;
    top: 15px;
    left: 15px;
  }
}

.big-logo {
  max-width: 380px;
}

#container {
  background-color: white;
  .content-header{
    height: 40px;
  }
}

.page-content{
  margin: 0 auto;
  padding: 0 1em;
  .block{
    padding: 0;
  }
  &:last-child{
    //margin-bottom: 1em;
  }
  table{
    margin-bottom: 1em;
  }
}

.pure-g.page-content.bottom-of-page-padding {
  .pure-u-1 {
    padding-bottom: 60px;
  }
}

footer{
  color: white;
  height: 150px;
  background: $footer-background;
  .footer-links {
    padding-top: 50px;
    text-align: center;
  }
  .gilmation-link {
    padding-top: 50px;
    margin-right: 10px;
  }
}

p {
  margin-bottom: 1em;
}

h1,h2,h3,h4,h5,h6{
  font-family: "Roboto Slab", serif;
}

h2 {
  margin: 0 0 1em 0;
  padding: 0.285em 0 0.285em 0;
  font-weight: normal;
  font-size: 1.5em;
  color: $c-h;
  border-bottom: 2px solid $c-h;
  text-transform: capitalize;
  line-height: 1.2em;
}

h3 {
  margin: 0 0 0.3em 0;
  padding: 0.285em 0 0.285em 0;
  font-weight: normal;
  font-size: 1.2em;
  color: $c-h;
  border-bottom: none;
  line-height: 1.2em;
}

h4 {
  margin: 1em 0 0 0;
  padding: 0.285em 0 0.285em 0;
  font-weight: normal;
  font-size: 1em;
  color: $c-h;
  border-bottom: none;
  text-transform: capitalize;
  line-height: 0.8em;
}

h4.no-margin {
  margin: 0;
}

#engage-home {
  text-align: center;
}

.intro {
  margin-bottom: 2em;
  img {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  }
}
/* use the best possible ampersand */
span.amp {
  font-family: Baskerville, "Goudy Old Style", "Palatino", "Book Antiqua", serif;
  font-weight: normal;
  font-style: italic;
  font-size: 1.2em;
  color: #766557;
}

span.drop {
  color: #A8B8C0;
  color: rgb(168, 184, 192);
  display:inline;
  float:left;
  font-size:3em;
  line-height:0.5em;
  margin:0;
  padding:0.25em 0.08em 0 0;
  text-transform:capitalize;
}

/* @group Links */

a:link, a:visited {
  font-weight: bold;
  text-decoration: none;
  outline: none;
  color: #012967;
  color: rgb(1, 41, 103);
}

a:hover {
  color: $c-gilmation-red-light;
}

#nav li a:hover  {
  color: $c-gilmation-red-light;
}

div.links {
  text-transform: capitalize;
}

div.links h3 {
  margin-bottom: 0.3em;
  line-height: 0.8em;
}

/* @end Links */

label em {
  color: rgb(0, 44, 27);
}

#flash-message-ok {
  color: #07672A;
  color: rgb(7, 103, 42);
  margin-bottom: 1em;
}

#flash-message-oops {
  color: $c-gilmation-red;
  margin-bottom: 1em;
}

#flash-message-timeout {
  color: $c-gilmation-red;
  margin-bottom: 1em;
}

.pure-button-primary, .pure-button-selected, a.pure-button-primary, a.pure-button-selected {
  background-color: $c-gilmation-red-light;
  border-radius: 5px;
}

a.pure-button-primary:hover {
  color: $c-gilmation-red;
}

.work {
  margin-top: 2em;
  .first {
    margin-right: 1em;
  }
}

.pure-g.page-content.bottom-of-page-padding {
  .pure-u-1.contact{
    padding-bottom: 220px;
  }
}

/*
 * -- TABLET (AND UP) MEDIA QUERIES --
 * On tablets and other medium-sized devices, we want to customize some
 * of the mobile styles.
 */
@media (min-width: 32em) {
  #container {
    background-color: white;
    .content-header{
      height: 40px;
    }
  }

  #engage-home {
    margin-top: 30px;
  }

  .page-content{
    .block{
      padding: 0 1em;
      &:first-child{
        padding-left: 0;
      }
    }
  }
  .about {
    img {
      padding: 10px 0px 0px 20px;
    }
  }
}

/*
 * -- DESKTOP (AND UP) MEDIA QUERIES --
 * On desktops and other large devices, we want to over-ride some
 * of the mobile and tablet styles.
 */
@media (min-width: 64em) {

  h2 {
    margin-bottom: 0.83em;
  }

  body {
    font-size: 16px;
  }

  header {
    .home-menu {
      text-align: left;
      .pure-menu-heading{
        float: left;
        padding: 10px 0 5px 5px;
        img{
          max-width: 400px;
        }
      }
    }
  }
  .pure-g.page-content.bottom-of-page-padding {
    .pure-u-1 {
      padding-bottom: 220px;
    }
  }
  #container{
    padding-bottom: 0px;
    .content-header{
      height: 40px;
    }
    .content-wrapper{
      position: absolute;
    }
    .content-wrapper .content .content-text-1{
      max-width: 1024px;
    }
  }
  .page-content{
    max-width: 1024px;
  }
  body{

  }
  footer{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 150px;
    background: $footer-background;
  }
}

/*
 * -- DESKTOP LG (AND UP) MEDIA QUERIES --
 * On desktops and other large devices, we want to over-ride some
 * of the mobile and tablet styles.
 */
@media (min-width: 80em) {
  #container {
    padding-bottom: 0px;
  }
  header {
    .home-menu {

    }
  }
  body{

  }
  footer{
    height: 150px;
  }
}

/*
 * -- DESKTOP XL (AND UP) MEDIA QUERIES --
 * On desktops and other large devices, we want to over-ride some
 * of the mobile and tablet styles.
 */
@media (min-width: 120em) {
  #container {
    padding-bottom: 0px;
    .content-wrapper .content .content-text-1{
      max-width: 1224px;
      padding: 3% 0;
    }
  }
  body{

  }
  footer{
    height: 150px;
  }
}
